import React from 'react'
import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'
import Img from 'gatsby-image'
import { Tag, Tags } from 'components/tag'

const Post = styled.li`
  background-color: white;
  position: relative;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  transition: box-shadow 0.5s ease-in-out;
  margin: 0 0 1em 0;
  width: 100%;
  border-radius: 16px;

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: ${props => (props.featured ? '0 0 66%' : '0 0 32%')};
  }

  &::before {
    content: ' ';
    position: absolute;
    border-radius: 16px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 10px 20px rgba(154, 160, 185, 0.2),
      0 30px 40px rgba(166, 173, 201, 0.3);
    opacity: 0;
    transition: opacity 500ms;
    pointer-events: none;
  }

  &:hover::before {
    opacity: 1;
  }
`

const Link = styled(GatsbyLink)`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: ${props => props.theme.colors.text};

  .gatsby-image-wrapper {
    height: 0;
    padding-bottom: 60%;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      padding-bottom: ${props => (props.featured ? '40%' : '60%')};
    }
  }
`

const TagContainer = styled(Tags)`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  margin-bottom: 0;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 1.5rem;
    margin-bottom: 0;
  }
`

const Image = styled(Img)`
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.314;
    background: linear-gradient(
      ${props => props.theme.colors.gradients.colorful}
    );
    mix-blend-mode: screen;
    pointer-events: none;
  }
`

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  margin: 0 1rem 0.5rem 1rem;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 0 1.5rem 0.5rem 1.5rem;
  }
`

const Date = styled.h3`
  margin: 0 1rem 0.5rem 1rem;
  font-family: ${props => props.theme.fonts.body};
  font-size: ${props => props.theme.fontSizes.xs};

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 0 1.5rem 0.5rem 1.5rem;
  }
`

const ReadingTime = styled.h4`
  margin: 1rem;
  margin-top: 0;
  font-family: ${props => props.theme.fonts.body};
  font-size: ${props => props.theme.fontSizes.xs};

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 0 1.5rem 1rem 1.5rem;
  }
`

const Excerpt = styled.p`
  margin: 1rem;
  margin-top: 0;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 1.5rem;
    margin-top: 0;
  }
`

const Card = ({
  author,
  slug,
  heroImage,
  title,
  publishDate,
  body,
  tags,
  metaDescription,
  ...props
}) => {
  return (
    <>
      {heroImage && body && (
        <Post featured={props.featured}>
          <div>
            <Link to={`${props.basePath}/${slug}/`} title="Artikel lesen">
              <Image
                alt={`${title} Cover`}
                fluid={heroImage.fluid}
                loading={'lazy'}
              />
            </Link>
            {tags && (
              <TagContainer>
                {tags.map((data, index) => (
                  <Tag key={index} {...data} path={props.basePath} />
                ))}
              </TagContainer>
            )}
            <Link to={`${props.basePath}/${slug}/`} title="Artikel lesen">
              <Title>{title}</Title>
              <Date>
                Von {author ? author.name : 'DEMV Systems'} | {publishDate}
              </Date>
              <ReadingTime>
                {body.childMarkdownRemark.timeToRead} Min. Lesezeit
              </ReadingTime>
              <Excerpt
                dangerouslySetInnerHTML={{
                  __html: metaDescription?.childMarkdownRemark?.excerpt || body.childMarkdownRemark.excerpt,
                }}
              />
            </Link>
          </div>
        </Post>
      )}
    </>
  )
}

export default Card
