import React from 'react'
import styled from '@emotion/styled'

const BaseList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 auto;
  &::after {
    content: '';
    flex: 0 0 32%;
  }
`

const List = props => {
  return <BaseList>{props.children}</BaseList>
}

export default List
